import { FC, useEffect } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NotFound from "../../components/errors/NotFound";
import useGlobalState from "../../hooks/useGlobalState";
import { Members } from "../../listsSettings/members";
import { getSchemaByInternalName } from "../../services/webapi";
import ActiveCompanies from "./companies/ActiveCompanies";
import AllItemsCompanies from "./companies/AllItems";
import DispFormCompanies from "./companies/DispForm";
import AllItemsLegalRepresentatives from "./legalRepresentatives/AllItems";
import DispFormLegalRepresentatives from "./legalRepresentatives/DispForm";
import AllItemsMembers from "./members/AllItems";
import DispFormMembers from "./members/DispForm";
import HGVMembers from "./members/HGVMembers";

const Home: FC = () => {
  const setCurrentSchema = useGlobalState((state) => state.setCurrentSchema);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const schema = await getSchemaByInternalName(Members.InternalName, abortController.signal);
      schema.data && setCurrentSchema(schema.data);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Routes>
      <Route path={`/`} element={<AllItemsMembers />} />

      <Route path={`_lists/${Members.Lists.Members.InternalName}`}>
        <Route index element={<AllItemsMembers />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsMembers />} />
          <Route path="hgv_members" element={<HGVMembers />} />
          <Route path="disp/:id" element={<DispFormMembers />} />
        </Route>
      </Route>

      <Route path={`_lists/${Members.Lists.Companies.InternalName}`}>
        <Route index element={<AllItemsCompanies />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsCompanies />} />
          <Route path="active_companies" element={<ActiveCompanies />} />
          <Route path="disp/:id" element={<DispFormCompanies />} />
        </Route>
      </Route>

      <Route path={`_lists/${Members.Lists.LegalRepresentatives.InternalName}`}>
        <Route index element={<AllItemsLegalRepresentatives />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsLegalRepresentatives />} />
          <Route path="disp/:id" element={<DispFormLegalRepresentatives />} />
        </Route>
      </Route>

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default Home;
