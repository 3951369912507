import classNames from "classnames";
import { parseISO } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Email from "../../../components/Email";
import Link from "../../../components/Link";
import Title from "../../../components/Title";
import Actions from "../../../components/controls/Actions";
import Errors from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTd from "../../../components/forms/FormTd";
import FormTr from "../../../components/forms/FormTr";
import { formatGender } from "../../../components/forms/GenderSelectBox";
import Textarea from "../../../components/forms/Textarea";
import DispPhoneNumber from "../../../components/phoneNumber/DispPhoneNumber";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import { Members } from "../../../listsSettings/members";
import { ErrorCode, IAction } from "../../../services/types";
import {
  formatBoolean,
  formatDateFromISOString,
  getCloseAction,
  getNumberFromUrl,
  setParamToUrl,
  standarizeUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import { Address } from "../addresses/types";
import styles from "./DispForm.module.css";
import * as Fields from "./Fields";
import { Member, renderCompanyType, renderOwnership, renderStatus, renderType } from "./types";
import { allItems } from "./urls";
import { getMember } from "./webapi";

interface ITab {
  id: number;
  title: string;
  hide?: boolean;
}
const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [state, setState] = useState<Member>();
  const [abortController, resetAbortController] = useAbortController();
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [companiesTabs, setCompaniesTabs] = useState<{
    selected?: ITab;
    tabs: ITab[];
  }>();
  const [legalSeatsTabs, setLegalSeatsTabs] = useState<{
    selected?: ITab;
    tabs: ITab[];
  }>();

  const paramMainTab = "t1";
  const paramTabLegalSeats = "t3";

  useEffect(() => {
    if (id) {
      initializeState(Number(id));
    }

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Members.Lists.Members.InternalName,
      Members.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getMember(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          if (result.data) {
            setState(result.data);

            const tabs: ITab[] = [
              {
                id: 0,
                title: "Mitglied",
              },
              ...(result.data.companies
                .sort((a, b) => (a.assertDate ?? "").localeCompare(b.assertDate))
                .map((x) => ({ id: x.id, title: x.name, onClick: () => {} })) ?? []),
            ];
            const t1 = getNumberFromUrl(paramMainTab);
            setCompaniesTabs({
              selected: tabs.filter((x) => x.id === t1)[0] ?? tabs[0],
              tabs: tabs,
            });

            const legalSeats = result.data.legalSeats
              .sort((a, b) => a.untilDate.localeCompare(b.untilDate))
              .reduce((rv: { id: number; from: string; to?: string }[], x) => {
                const prevTo = rv[rv.length - 1]?.to;
                const from = prevTo ? getStringDayAfter(prevTo) : result.data?.entryDate;
                from && rv.push({ id: x.id, from: from, to: x.untilDate });
                return rv;
              }, []);

            var currentDate = new Date();
            const lastTo = legalSeats[legalSeats.length - 1]?.to;
            if (lastTo) {
              const lastToDate = parseISO(lastTo);
              lastToDate.setDate(lastToDate.getDate() + 1);
              currentDate = lastToDate;
            } else if (result.data.entryDate) {
              currentDate = parseISO(result.data.entryDate);
            }

            const legalSeatsTabs: ITab[] = [...legalSeats, { id: 0, from: currentDate.toISOString() }].map((x) => ({
              id: x.id,
              title: `${formatDateFromISOString(x.from)} - ${formatDateFromISOString(x.to)}`,
            }));

            const t3 = getNumberFromUrl(paramTabLegalSeats);
            setLegalSeatsTabs({
              selected: legalSeatsTabs.filter((x) => x.id === t3)[0] ?? legalSeatsTabs[legalSeatsTabs.length - 1],
              tabs: legalSeatsTabs,
            });
          }
        }
      }
    }
  };

  const getStringDayAfter = (date: string) => {
    const toDate = parseISO(date);
    toDate.setDate(toDate.getDate() + 1);
    return toDate.toISOString();
  };

  const closeAction: IAction = getCloseAction(navigateTo, allItems);

  const legalSeat: { name?: string; address?: Address } = !legalSeatsTabs?.selected?.id
    ? {
        name: state?.name,
        address: state?.address,
      }
    : {
        name: state?.legalSeats.filter((x) => x.id === legalSeatsTabs?.selected?.id)[0]?.name,
        address: state?.legalSeats.filter((x) => x.id === legalSeatsTabs?.selected?.id)[0]?.address,
      };

  const company = state?.companies.filter((x) => x.id === companiesTabs?.selected?.id)[0];

  useEffect(() => {
    if (state) {
      setListName(Members.Lists.Members.Title);
      setPage(state.name ?? null);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text="Mitglied" />
          <Actions actions={[closeAction]} />

          <div className={classNames(styles.tabs, styles.background)}>
            {companiesTabs?.tabs.map((x) => (
              <div
                key={x.title}
                className={classNames(styles.tab, {
                  [styles["tab-active"]]: companiesTabs.selected?.id === x.id,
                })}
                onClick={() => {
                  setCompaniesTabs({
                    ...companiesTabs,
                    selected: x,
                  });
                  setParamToUrl(navigateTo, paramMainTab, x.id);
                }}
              >
                {x.title}
              </div>
            ))}
          </div>
          <div className={styles.line}></div>
          {!companiesTabs?.selected?.id && !!legalSeatsTabs?.tabs.length && (
            <>
              <div className={styles.tabs}>
                {legalSeatsTabs?.tabs.map((x) => (
                  <div
                    key={x.title}
                    className={classNames(styles["sub-tab"], styles.italic, {
                      [styles["sub-tab-active"]]: legalSeatsTabs.selected?.id === x.id,
                    })}
                    onClick={() => {
                      setLegalSeatsTabs({
                        ...legalSeatsTabs,
                        selected: x,
                      });
                      setParamToUrl(navigateTo, paramTabLegalSeats, x.id);
                    }}
                  >
                    {x.title}
                  </div>
                ))}
              </div>
              <div className={styles.line}></div>
            </>
          )}
          <div>&nbsp;</div>
          {companiesTabs?.selected?.id === 0 && (
            <FormTable>
              <tr>
                <td colSpan={6}>
                  <b>Mitgliedsdaten</b>
                </td>
              </tr>
              <FormTr>
                <FormLabel minWidth>{Fields.name.title}</FormLabel>
                <FormData disp fitContent>
                  {legalSeat.name}
                </FormData>
                <FormLabel minWidth>Mitgliedsnummer</FormLabel>
                <FormData disp fitContent>
                  {state.id}
                </FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>{Fields.street.title}</FormLabel>
                <FormData disp fitContent>
                  {legalSeat.address?.street}
                </FormData>
                <FormLabel minWidth>{Fields.city.title}</FormLabel>
                <FormData disp fitContent>
                  {legalSeat.address?.city}
                </FormData>
                <FormLabel minWidth>PLZ</FormLabel>
                <FormData disp fitContent>
                  {legalSeat.address?.zip}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>{Fields.phone.title}</FormLabel>
                <FormData disp fitContent>
                  <DispPhoneNumber value={state.phone} />
                </FormData>
                <FormLabel minWidth>{Fields.email.title}</FormLabel>
                <FormData disp fitContent>
                  <Email value={state.email} />
                </FormData>
                <FormLabel minWidth>PEC</FormLabel>
                <FormData disp fitContent>
                  <Email value={state.pec} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Webseite</FormLabel>
                <FormData disp fitContent>
                  {state.website && <Link href={standarizeUrl(state.website)} openInNewTab external />}
                </FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>UID Nr.</FormLabel>
                <FormData disp fitContent>
                  {state.uidNr}
                </FormData>
                <FormLabel minWidth>MwSt.</FormLabel>
                <FormData disp fitContent>
                  {state.vat}
                </FormData>
                <FormLabel minWidth>St.-Nr.</FormLabel>
                <FormData disp fitContent>
                  {state.taxCode}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Firmentyp</FormLabel>
                <FormData disp fitContent>
                  {renderCompanyType(state.companyType)}
                </FormData>
                <FormLabel minWidth>Rechtsform</FormLabel>
                <FormData disp fitContent>
                  {state.legalForm}
                </FormData>
                <FormLabel minWidth>Geschlecht</FormLabel>
                <FormData disp fitContent>
                  {formatGender(state.gender)}
                </FormData>
              </FormTr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
              <FormTr>
                <FormLabel minWidth noWrap>
                  Ist HGV-Mitglied
                </FormLabel>
                <FormData disp fitContent>
                  {formatBoolean(state.isMember)}
                </FormData>
                <FormLabel minWidth>Art</FormLabel>
                <FormData disp fitContent>
                  {renderType(state.type)}
                </FormData>
                <FormLabel minWidth>Status</FormLabel>
                <FormData disp fitContent>
                  {renderStatus(state.status)}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Eintrittsdatum</FormLabel>
                <FormData disp fitContent>
                  {formatDateFromISOString(state.entryDate)}
                </FormData>
                <FormLabel minWidth>Austrittsdatum</FormLabel>
                <FormData disp fitContent>
                  {formatDateFromISOString(state.exitDate)}
                </FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Austrittsgrund</FormLabel>
                <FormTd colSpan={5}>
                  <Textarea value={state.exitReason ?? ""} readOnly rows={2} />
                </FormTd>
              </FormTr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
              <FormTr>
                <FormLabel minWidth>Bemerkungen</FormLabel>
                <FormTd colSpan={5}>
                  <Textarea value={state.notes ?? ""} readOnly rows={5} />
                </FormTd>
              </FormTr>
              <tr>
                <td colSpan={6}>
                  <CreatedModifiedInfo info={state} />
                </td>
              </tr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <b>Gesetzlicher Vertreter</b>
                </td>
              </tr>
              <FormTr>
                <FormLabel minWidth>Vorname</FormLabel>
                <FormData disp fitContent>
                  {state.legalRepresentative?.lastName}
                </FormData>
                <FormLabel minWidth>Nachname</FormLabel>
                <FormData disp fitContent>
                  {state.legalRepresentative?.firstName}
                </FormData>
                <FormLabel minWidth>St.-Nr.</FormLabel>
                <FormData disp fitContent>
                  {state.legalRepresentative?.taxCode}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Straße</FormLabel>
                <FormData disp fitContent>
                  {state.legalRepresentative?.address.street}
                </FormData>
                <FormLabel minWidth>Ort</FormLabel>
                <FormData disp fitContent>
                  {state.legalRepresentative?.address.city}
                </FormData>
                <FormLabel minWidth>PLZ</FormLabel>
                <FormData disp fitContent>
                  {state.legalRepresentative?.address.zip}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Telefon</FormLabel>
                <FormData disp fitContent>
                  <DispPhoneNumber value={state.legalRepresentative?.phone} />
                </FormData>
                <FormLabel minWidth>Mobil</FormLabel>
                <FormData disp fitContent>
                  <DispPhoneNumber value={state.legalRepresentative?.mobile} />
                </FormData>
                <FormLabel minWidth>Email</FormLabel>
                <FormData disp fitContent>
                  <Email value={state.legalRepresentative?.email} />
                </FormData>
              </FormTr>
              <tr>
                <td colSpan={6}>
                  <CreatedModifiedInfo info={state.legalRepresentative} />
                </td>
              </tr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            </FormTable>
          )}
          {company && (
            <FormTable>
              <tr>
                <td colSpan={6}>
                  <b>Mitgliedsbetriebsdaten</b>
                </td>
              </tr>
              <FormTr>
                <FormLabel minWidth>Betriebsname</FormLabel>
                <FormData disp fitContent>
                  {company.name}
                </FormData>
                <FormLabel minWidth>Mitgliedsbetriebnummer</FormLabel>
                <FormData disp fitContent>
                  {company.id}
                </FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Strasse</FormLabel>
                <FormData disp fitContent>
                  {company.address.street}
                </FormData>
                <FormLabel minWidth>Ort</FormLabel>
                <FormData disp fitContent>
                  {company.address.city}
                </FormData>
                <FormLabel minWidth>PLZ</FormLabel>
                <FormData disp fitContent>
                  {company.address.zip}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Telefon</FormLabel>
                <FormData disp fitContent>
                  <DispPhoneNumber value={company.phone} />
                </FormData>
                <FormLabel minWidth>E-Mail</FormLabel>
                <FormData disp fitContent>
                  <Email value={company.email} />
                </FormData>
                <FormLabel minWidth>PEC</FormLabel>
                <FormData disp fitContent>
                  <Email value={company.pec} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Webseite</FormLabel>
                <FormData disp fitContent></FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
              </FormTr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
              <FormTr>
                <FormLabel minWidth>Anmeldedatum</FormLabel>
                <FormData disp fitContent>
                  {formatDateFromISOString(company.assertDate)}
                </FormData>
                <FormLabel minWidth>Abgemeldet</FormLabel>
                <FormData disp fitContent>
                  {formatDateFromISOString(company.cancelDate)}
                </FormData>
                <FormLabel minWidth>Abmeldegrund</FormLabel>
                <FormData disp fitContent>
                  {company.cancelReason}
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel minWidth>Besitzverhaeltnis</FormLabel>
                <FormData disp fitContent>
                  {renderOwnership(company.ownership)}
                </FormData>
                <FormLabel minWidth>Betrieb verpachtet</FormLabel>
                <FormData disp fitContent>
                  {formatBoolean(company.leased)}
                </FormData>
                <FormLabel minWidth></FormLabel>
                <FormData disp fitContent></FormData>
              </FormTr>
              <FormTr>
                <FormLabel noWrap>Ortsgruppe</FormLabel>
                <FormData disp fitContent>
                  {company.chapter?.name}
                </FormData>
                <FormLabel noWrap>Code</FormLabel>
                <FormData disp fitContent>
                  {company.chapter?.code}
                </FormData>
                <FormLabel minWidth>Seit</FormLabel>
                <FormData disp fitContent>
                  {formatDateFromISOString(company.entryDateChapter)}
                </FormData>
              </FormTr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            </FormTable>
          )}
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
