import { Item } from "../../../services/types";
import { Address } from "../addresses/types";
import { Company } from "../companies/types";
import { LegalRepresentative } from "../legalRepresentatives/types";
import { LegalSeat } from "../legalSeats/types";

export const isMember = (member?: Member): boolean => member?.type == ordinary && member?.status == definitive;

const canceled = "canceled";
const definitive = "definitive";
export const renderStatus = (status?: string | null): string => {
  switch (status) {
    case canceled: {
      return "Annulliert";
    }
    case definitive: {
      return "Definitiv";
    }
    default: {
      return status ?? "";
    }
  }
};

const ordinary = "ordinary";
const extraordinary = "extraordinary";
const client = "client";
const freeNewspaper = "free-newspaper";
const newspaperSubscription = "newspaper-subscription";
const domesticWorkers = "domestic-worker";
export const renderType = (type?: string | null): string => {
  switch (type) {
    case ordinary: {
      return "Ordentlich";
    }
    case extraordinary: {
      return "Außerordentlich";
    }
    case client: {
      return "Kunde";
    }
    case freeNewspaper: {
      return "Gratis-Zeitung";
    }
    case newspaperSubscription: {
      return "Zeitung-Abo";
    }
    case domesticWorkers: {
      return "Hausangestellte";
    }
    default: {
      return type ?? "";
    }
  }
};

const company = "company";
const soleProprietorship = "soleProprietorship";
const privatePerson = "privatePerson";
export const renderCompanyType = (type?: string | null): string => {
  switch (type) {
    case company: {
      return "Gesellschaft";
    }
    case soleProprietorship: {
      return "Einzelunternehmen";
    }
    case privatePerson: {
      return "Privatperson";
    }
    default: {
      return type ?? "";
    }
  }
};

const leaseholder = "leaseholder";
const owner = "owner";
export const renderOwnership = (ownership?: string | null): string => {
  switch (ownership) {
    case leaseholder: {
      return "Pächter";
    }
    case owner: {
      return "Besitzer";
    }
    default: {
      return ownership ?? "";
    }
  }
};

export interface Member extends Item {
  id: number;
  entryDate: string | null;
  exitDate: string | null;
  exitReason: string | null;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  taxCode: string | null;
  vat: string | null;
  uidNr: string | null;
  email: string | null;
  pec: string | null;
  phone: string | null;
  type: string | null;
  status: string | null;
  companyType: string | null;
  legalForm: string | null;
  gender: "male" | "female" | "entity" | null;
  website: string | null;
  notes: string | null;
  address: Address;
  isMember: boolean;
  companies: Company[];
  legalSeats: LegalSeat[];
  legalRepresentative: LegalRepresentative | null;
}
