const Chapters = {
  InternalName: "chapters",
  Title: "Ortsgruppen",
};
const LegalRepresentatives = {
  InternalName: "legal_representatives",
  Title: "Gesetzliche Vertreter",
};
const LegalSeats = {
  InternalName: "legal_seats",
  Title: "Rechtsitze",
};
const Companies = {
  InternalName: "companies",
  Title: "Mitgliedsbetriebe",
};
const Members_ = {
  InternalName: "members",
  Title: "Mitglieder",
};

export const Members = {
  InternalName: "members",
  Title: "Mitglieder",
  Lists: {
    Chapters: Chapters,
    LegalRepresentatives: LegalRepresentatives,
    LegalSeats: LegalSeats,
    Companies: Companies,
    Members: Members_,
  },
};
