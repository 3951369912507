import { FC, useEffect, useState } from "react";
import Check from "../../svg/Check.svg?react";
import Cross from "../../svg/Cross.svg?react";
import styles from "./TaxCode.module.css";

const months = "ABCDEHLMPRST";

export const getGenderFromTaxcode = (taxCode: string | null | undefined): "male" | "female" | null => {
  if (!taxCode) {
    return null;
  }

  const day = Number(taxCode.substring(9, 11));
  return day > 0 && day < 32 ? "male" : day > 40 && day < 72 ? "female" : null;
};

export const getBirthdate = (taxCode: string | null | undefined, gender: "male" | "female" | null): string | null => {
  if (!taxCode || taxCode.length < 15) {
    return null;
  }

  const year = taxCode.substring(6, 8);
  const month = taxCode.substring(8, 9);
  const dayString = taxCode.substring(9, 11);
  const day = Number(dayString) - (gender === "female" ? 40 : 0);

  const thisCentury = Math.floor(new Date().getFullYear() / 100);
  const currentYear = new Date().getFullYear().toString().substring(2, 4);

  const y = `${currentYear > year ? thisCentury : thisCentury - 1}${year}`;
  const m = (months.indexOf(month) + 1).toString().padStart(2, "0");
  const d = day.toString().padStart(2, "0");

  return `${y}-${m}-${d}`;
};

export const isTaxCodeValid = (taxCode: string): boolean => {
  taxCode = taxCode.toUpperCase();
  if (taxCode.length != 16) return false;

  const valid = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let i;
  for (i = 0; i < 16; i++) {
    if (valid.indexOf(taxCode.charAt(i)) == -1) return false;
  }

  const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const setEven = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const setOdd = "BAKPLCQDREVOSFTGUHMINJWZYX";
  let s = 0;
  for (i = 1; i <= 13; i += 2) {
    s += setEven.indexOf(set2.charAt(set1.indexOf(taxCode.charAt(i))));
  }
  for (i = 0; i <= 14; i += 2) {
    s += setOdd.indexOf(set2.charAt(set1.indexOf(taxCode.charAt(i))));
  }
  if (s % 26 != taxCode.charCodeAt(15) - "A".charCodeAt(0)) return false;

  return true;
};

const getStateValue = (value: string | null): boolean | null => (!value ? null : isTaxCodeValid(value));

interface TaxCodeProps {
  value: string | null;
  onChange: (x: string | null) => void;
}

const TaxCode: FC<TaxCodeProps> = (props: TaxCodeProps) => {
  const [state, setState] = useState<boolean | null>(getStateValue(props.value));

  const update = (value: string | null) => {
    const isValid = getStateValue(value?.toUpperCase() || null);
    props.onChange(value);

    setState(isValid);
  };

  useEffect(() => {
    update(props.value);
  }, [props.value]);

  return (
    <div className={styles.container}>
      <input type="text" defaultValue={props.value ?? ""} onChange={(event) => update(event.target.value)} />

      <div className={styles.icons}>
        {state === true && (
          <span title="OK" className={styles.valid}>
            <Check />
          </span>
        )}
        {state === false && (
          <span title="Ungültig" className={styles.invalid}>
            <Cross />
          </span>
        )}
      </div>
    </div>
  );
};

export default TaxCode;
