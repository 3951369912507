import { FC, useEffect } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";

import useGlobalState from "../../hooks/useGlobalState";
import { Administration } from "../../listsSettings/administration";
import { getSchemaByInternalName } from "../../services/webapi";
import AllItemsCurrentNavigationItems from "./currentNavigationItems/AllItems";
import DispFormCurrentNavigationItems from "./currentNavigationItems/DispForm";
import EditFormCurrentNavigationItems from "./currentNavigationItems/EditForm";
import NewFormCurrentNavigationItems from "./currentNavigationItems/NewForm";
import AllItemsGlobalNavigationItems from "./globalNavigationItems/AllItems";
import DispFormGlobalNavigationItems from "./globalNavigationItems/DispForm";
import EditFormGlobalNavigationItems from "./globalNavigationItems/EditForm";
import NewFormGlobalNavigationItems from "./globalNavigationItems/NewForm";
import AllItemsGroups from "./groups/AllItems";
import DispFormGroups from "./groups/DispForm";
import EditFormGroups from "./groups/EditForm";
import NewFormGroups from "./groups/NewForm";
import AllItemsLists from "./lists/AllItems";
import DispFormLists from "./lists/DispForm";
import EditFormLists from "./lists/EditForm";
import NewFormLists from "./lists/NewForm";
import AllItemsOffices from "./offices/AllItems";
import DispFormOffices from "./offices/DispForm";
import AllItemsPermissions from "./permissions/AllItems";
import DispFormPermissions from "./permissions/DispForm";
import EditFormPermissions from "./permissions/EditForm";
import NewFormPermissions from "./permissions/NewForm";
import AllItemsSchemas from "./schemas/AllItems";
import DispFormSchemas from "./schemas/DispForm";
import EditFormSchemas from "./schemas/EditForm";
import NewFormSchemas from "./schemas/NewForm";
import AllItemsUsers from "./users/AllItems";
import UsersBozen from "./users/Bozen";
import UsersBrixen from "./users/Brixen";
import UsersBruneck from "./users/Bruneck";
import DispFormUsers from "./users/DispForm";
import UsersMeran from "./users/Meran";
import UsersSchlanders from "./users/Schlanders";
import AllItemsViews from "./views/AllItems";
import DispFormViews from "./views/DispForm";
import EditFormViews from "./views/EditForm";
import NewFormViews from "./views/NewForm";

import NotFound from "../../components/errors/NotFound";
import AllItemsCountries from "./countries/AllItems";
import DispFormCountries from "./countries/DispForm";
import EditFormCountries from "./countries/EditForm";
import NewFormCountries from "./countries/NewForm";

const Default: FC = () => {
  const setListName = useGlobalState((state) => state.setListName);
  const setPage = useGlobalState((state) => state.setPage);
  useEffect(() => {
    setListName(null);
    setPage(null);
  }, []);

  return null;
};

const Home: FC = () => {
  const setCurrentSchema = useGlobalState((state) => state.setCurrentSchema);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const schema = await getSchemaByInternalName(Administration.InternalName, abortController.signal);
      schema.data && setCurrentSchema(schema.data);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Default></Default>} />

      <Route path={`_lists/${Administration.Lists.Countries.InternalName}`}>
        <Route index element={<AllItemsCountries />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsCountries />} />
          <Route path="new" element={<NewFormCountries />} />
          <Route path="disp/:id" element={<DispFormCountries />} />
          <Route path="edit/:id" element={<EditFormCountries />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Schemas.InternalName}`}>
        <Route index element={<AllItemsSchemas />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsSchemas />} />
          <Route path="new" element={<NewFormSchemas />} />
          <Route path="disp/:id" element={<DispFormSchemas />} />
          <Route path="edit/:id" element={<EditFormSchemas />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Users.InternalName}`}>
        <Route index element={<AllItemsUsers />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsUsers />} />
          <Route path="bozen" element={<UsersBozen />} />
          <Route path="meran" element={<UsersMeran />} />
          <Route path="brixen" element={<UsersBrixen />} />
          <Route path="bruneck" element={<UsersBruneck />} />
          <Route path="schlanders" element={<UsersSchlanders />} />
          <Route path="disp/:id" element={<DispFormUsers />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Schemas.InternalName}`}>
        <Route index element={<AllItemsSchemas />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsSchemas />} />
          <Route path="new" element={<NewFormSchemas />} />
          <Route path="disp/:id" element={<DispFormSchemas />} />
          <Route path="edit/:id" element={<EditFormSchemas />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Permissions.InternalName}`}>
        <Route index element={<AllItemsPermissions />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsPermissions />} />
          <Route path="new" element={<NewFormPermissions />} />
          <Route path="disp/:name" element={<DispFormPermissions />} />
          <Route path="edit/:name" element={<EditFormPermissions />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Groups.InternalName}`}>
        <Route index element={<AllItemsGroups />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsGroups />} />
          <Route path="new" element={<NewFormGroups />} />
          <Route path="disp/:id" element={<DispFormGroups />} />
          <Route path="edit/:id" element={<EditFormGroups />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Views.InternalName}`}>
        <Route index element={<AllItemsViews />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsViews />} />
          <Route path="new" element={<NewFormViews />} />
          <Route path="disp/:id" element={<DispFormViews />} />
          <Route path="edit/:id" element={<EditFormViews />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Lists.InternalName}`}>
        <Route index element={<AllItemsLists />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsLists />} />
          <Route path="new" element={<NewFormLists />} />
          <Route path="disp/:id" element={<DispFormLists />} />
          <Route path="edit/:id" element={<EditFormLists />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.GlobalNavigationItems.InternalName}`}>
        <Route index element={<AllItemsGlobalNavigationItems />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsGlobalNavigationItems />} />
          <Route path="new" element={<NewFormGlobalNavigationItems />} />
          <Route path="disp/:id" element={<DispFormGlobalNavigationItems />} />
          <Route path="edit/:id" element={<EditFormGlobalNavigationItems />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.CurrentNavigationItems.InternalName}`}>
        <Route index element={<AllItemsCurrentNavigationItems />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsCurrentNavigationItems />} />
          <Route path="new" element={<NewFormCurrentNavigationItems />} />
          <Route path="disp/:id" element={<DispFormCurrentNavigationItems />} />
          <Route path="edit/:id" element={<EditFormCurrentNavigationItems />} />
        </Route>
      </Route>

      <Route path={`_lists/${Administration.Lists.Offices.InternalName}`}>
        <Route index element={<AllItemsOffices />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsOffices />} />
          <Route path="disp/:id" element={<DispFormOffices />} />
        </Route>
      </Route>

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default Home;
