import classNames from "classnames";
import { format, isValid } from "date-fns";
import { FC, useEffect, useState } from "react";
import { getString } from "../../../components/errors/Errors";
import DatePicker from "../../../components/forms/DatePickerWithInput";
import Email from "../../../components/forms/Email";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { formatGender } from "../../../components/forms/GenderSelectBox";
import Input from "../../../components/forms/Input";
import LanguageSelectBox from "../../../components/forms/LanguageSelectBox";
import TaxCode, { getBirthdate, getGenderFromTaxcode } from "../../../components/forms/TaxCode";
import EditPhoneNumber, { PhoneCountry } from "../../../components/phoneNumber/EditPhoneNumber";
import useStatusBarState from "../../../hooks/useStatusBar";
import { ValidationErrors } from "../../../services/types";
import Check from "../../../svg/Check.svg?react";
import Cross from "../../../svg/Cross.svg?react";
import Warning from "../../../svg/Warning.svg?react";
import { getCountries } from "../../administration/countries/webapi";
import * as Fields from "./Fields";
import styles from "./NewEditTable.module.css";
import { PersonForm } from "./types";

interface NewEditTableProps {
  state: PersonForm;
  setState: React.Dispatch<React.SetStateAction<PersonForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  const statusBar = useStatusBarState();
  const [countries, setCountries] = useState<PhoneCountry[]>([]);
  const [birthdate, setBirthdate] = useState<{ value: string | null; state: "ok" | "warning" | "error" | "" }>();

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const countriesResult = await getCountries(
        {
          filters: [
            {
              fieldname: "phoneCode",
              operator: "IS_NOT_NULL",
            },
          ],
        },
        abortController.signal,
      );

      countriesResult.error && statusBar.addError(getString(countriesResult.error));
      countriesResult.data && setCountries(countriesResult.data.items.filter((x) => x.phoneCode));
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    checkBirthdate();
  }, [state.taxCode, state.birthdate]);

  const checkBirthdate = () => {
    const date = getBirthdate(state.taxCode, state.gender);
    const d2 = state.birthdate
      ? (() => {
          const d = new Date(state.birthdate);
          return isValid(d) ? format(d, "yyyy-MM-dd") : "";
        })()
      : "";

    const s =
      date === d2
        ? "ok"
        : date?.substring(2, 10) === d2.substring(2, 10)
          ? "warning"
          : date && state.birthdate
            ? "error"
            : "";
    setBirthdate({
      value: date,
      state: s,
    });
  };

  return (
    <FormTable>
      <FormTr>
        <FormLabel>{Fields.salutation.title}</FormLabel>
        <FormData>
          <Input
            value={state.salutation}
            onChange={(value) =>
              setState({
                ...state,
                salutation: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.language.title}</FormLabel>
        <FormData>
          <LanguageSelectBox
            value={state.language}
            onChange={(value) =>
              setState({
                ...state,
                language: value,
              })
            }
            languagesToShow={["de", "it"]}
            allowEmpty
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.firstName.title}</FormLabel>
        <FormData>
          <Input
            value={state.firstName}
            onChange={(value) =>
              setState({
                ...state,
                firstName: value,
              })
            }
          />
          <FormError error={errors[Fields.firstName.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.lastName.title}</FormLabel>
        <FormData>
          <Input
            value={state.lastName}
            onChange={(value) =>
              setState({
                ...state,
                lastName: value,
              })
            }
          />
          <FormError error={errors[Fields.lastName.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.taxCode.title}</FormLabel>
        <FormData>
          <TaxCode
            value={state.taxCode}
            onChange={(value) =>
              setState({
                ...state,
                taxCode: value,
              })
            }
          />
          <FormError error={errors[Fields.taxCode.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.gender.title}</FormLabel>
        <FormData disp>{formatGender(getGenderFromTaxcode(state?.taxCode))}</FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.birthplace.title}</FormLabel>
        <FormData>
          <div className={classNames(styles.inline, styles.birthdate)}>
            <Input
              value={state.birthplace}
              onChange={(value) =>
                setState({
                  ...state,
                  birthplace: value,
                })
              }
            />
          </div>
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.birthdate.title}</FormLabel>
        <FormData>
          <div className={styles.inline}>
            <DatePicker
              value={state.birthdate}
              onSelect={(value) =>
                setState({
                  ...state,
                  birthdate: value,
                })
              }
            />
            <div className={styles.icons}>
              {birthdate?.state === "ok" && (
                <span title="Geburtsdatum stimmt überein" className={styles.valid}>
                  <Check />
                </span>
              )}
              {birthdate?.state === "warning" && (
                <span title="Geburtsdatum stimmt möglicherweise nicht überein" className={styles.warning}>
                  <Warning />
                </span>
              )}
              {birthdate?.state === "error" && (
                <span title="Geburtsdatum stimmt nicht überein" className={styles.invalid}>
                  <Cross />
                </span>
              )}
            </div>
          </div>
          <FormError error={errors[Fields.birthdate.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.phone.title}</FormLabel>
        <FormData>
          <EditPhoneNumber
            countries={countries}
            value={state.phone}
            onChange={(value) =>
              setState({
                ...state,
                phone: value,
              })
            }
          />
          <FormError error={errors[Fields.phone.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.mobile.title}</FormLabel>
        <FormData>
          <EditPhoneNumber
            countries={countries}
            value={state.mobile}
            onChange={(value) =>
              setState({
                ...state,
                mobile: value,
              })
            }
          />
          <FormError error={errors[Fields.mobile.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.email.title}</FormLabel>
        <FormData>
          <Email
            value={state.email}
            onChange={(value) =>
              setState({
                ...state,
                email: value,
              })
            }
          />
          <FormError error={errors[Fields.email.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.street.title}</FormLabel>
        <FormData>
          <Input
            value={state.street}
            onChange={(value) =>
              setState({
                ...state,
                street: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.zip.title}</FormLabel>
        <FormData>
          <Input
            value={state.zip}
            onChange={(value) =>
              setState({
                ...state,
                zip: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.city.title}</FormLabel>
        <FormData>
          <Input
            value={state.city}
            onChange={(value) =>
              setState({
                ...state,
                city: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.province.title}</FormLabel>
        <FormData>
          <Input
            value={state.province}
            onChange={(value) =>
              setState({
                ...state,
                province: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.country.title}</FormLabel>
        <FormData>
          <Input
            value={state.country}
            onChange={(value) =>
              setState({
                ...state,
                country: value,
              })
            }
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
